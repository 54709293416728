import React from "react"
import Throbber from "./throbber"
import ActivityCard from "./activity-card"
import { Trans } from "react-i18next"
import BonusScoreCard from "./bonus-score-card"

const CompletedActivities = ({
  completedActivities,
  loading,
  bonusScoreList,
}) => {
  if (loading) return <Throbber relative loadingText="Loading..." />

  return (
    <>
      {completedActivities.length > 0 ? (
        <div>
          <div className="row margin--top-40">
            <h1 className="subtitle uppercase center no-margin--bottom">
              <Trans i18nKey="completed-activity.title" />
            </h1>
          </div>
          <br />
          <div className="card__grid">
            {completedActivities.map(activity => (
              <ActivityCard key={activity.contentfulId} {...activity} />
            ))}
          </div>
          <br />
          <br />
        </div>
      ) : null}
      {bonusScoreList.length > 0 ? (
        <>
          <div>
            <div className="row margin--top-40">
              <h1 className="subtitle uppercase center no-margin--bottom">
                <Trans i18nKey="completed-activity.extra_points" />
              </h1>
            </div>
            <br />
            <div className="card__grid">
              {bonusScoreList.map((card, index) => (
                <BonusScoreCard
                  key={`${index}_${card.id}`}
                  cardDetails={card}
                />
              ))}
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default CompletedActivities
