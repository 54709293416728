import React from "react"
import { useTranslation } from "react-i18next"
import { SCHEMA_LANGUAGES } from "../constants"
import logo from "../assets/brands/te/logo.png"

const BonusScoreCard = ({ cardDetails }) => {
  const { i18n, t } = useTranslation()

  const getComment = () => {
    let itemContent = ""
    if (cardDetails.localization) {
      const languageCode = SCHEMA_LANGUAGES[i18n.language]

      if (cardDetails.localization[languageCode]) {
        itemContent = cardDetails.localization[languageCode].content
      }
    }
    return itemContent
  }

  return (
    <>
      <div
        className="card__wrapper"
        style={{
          backgroundColor: cardDetails.backgroundColor
            ? cardDetails.backgroundColor
            : "rgb(246, 193, 147)",
        }}
      >
        <div
          className="card__header"
          style={{
            backgroundColor: "#000",
            color: cardDetails.backgroundColor
              ? cardDetails.backgroundColor
              : "rgb(246, 193, 147)",
          }}
        >
          {cardDetails.type === "RIDDLE"
            ? t(`completed-activity.riddle`)
            : t(`completed-activity.referral`)}
        </div>
        <div className="progress__point__circle progress__point__circle--small ">
          {cardDetails.points}
        </div>

        <img className="card__image margin--top-24" src={logo} alt="" />
        <div className="card__text">
          {cardDetails.type && cardDetails.type === "REFERRAL" ? (
            <p className="card__subtitle">{`${t(
              "completed-activity.referral"
            )}: ${cardDetails.referralCount}`}</p>
          ) : (
            <p className="card__subtitle">
              {getComment() || cardDetails.comment}
            </p>
          )}
        </div>
      </div>
    </>
  )
}

export default BonusScoreCard
