import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { useQuery } from "@apollo/react-hooks"
import Layout from "../components/layout"
import PointProgress from "../components/point-progress"
import CompletedActivities from "../components/completed-activities"
// import CompletedJourneys from "../components/completed-journeys"
import TETorch from "./../assets/brands/te/te-torch.png"
import { Trans, withTranslation } from "react-i18next"
import { USER_JOURNEYS_ACTIVITIES, ME } from "../gql"
import { separateAndSummarizeRiddleData } from "../utilities/helpers"

// The query below uses a fragment defined in the gatsby_queries folder to keep the required activity properties DRY.
export const allJourneysWithActivities = graphql`
  query allJourneysWithActivities2 {
    allContentfulJourney {
      nodes {
        ...JourneyWithActivities
      }
    }
  }
`

const Progress = ({ data, location }) => {
  const [bonusScoreList, setBonusScoreList] = useState([])
  const {
    allContentfulJourney: { nodes: allJourneys },
  } = data
  const { data: myData, loading } = useQuery(USER_JOURNEYS_ACTIVITIES, {
    fetchPolicy: "network-only",
  })

  const { data: meData, loading: meDataLoading } = useQuery(ME, {
    fetchPolicy: "network-only",
  })
  const [hasPoints, setHasPoints] = useState(false)
  // const [completedJourneys, setCompletedJourneys] = useState([])
  const [completedActivities, setCompletedActivities] = useState([])

  useEffect(() => {
    if (myData && myData.me) {
      let mergedActivities = []
      // Iterate all journeys, and locate the user journey by ID.
      const tempCompletedJourneys = allJourneys.filter(journey => {
        const myJourney = myData.me.journeys.find(myJourney => {
          return myJourney.contentfulId === journey.contentful_id
        })

        // Filter only the completed activities.
        if (myJourney) {
          const completedActivities = myJourney.activities.filter(
            activity => activity.completed
          )
          journey.activities.forEach(journeyActivity => {
            const activeActivity = completedActivities.find(myActivity => {
              return myActivity.contentfulId === journeyActivity.contentful_id
            })
            if (activeActivity) {
              let mergedActivity = {
                journeyColor: journey.color,
                journeyTitle: journey.title,
                journeySlug: journey.slug,
                journeyIllustration: journey.illustration,
                headerColor: journey.category.color,
                contentfulId: journeyActivity.contentful_id,
                ...journeyActivity, // The activity specific data like title, illustration etc.
                ...activeActivity, // The activity progress, active/completed status etc.
              }
              mergedActivities.push(mergedActivity)
            }
          })

          // If the length of the completedActivities array is equal to the length of all the activities on the journey, the journey has been completed.
          return completedActivities.length === myJourney.activities.length
        }
        return false
      })

      setCompletedActivities(mergedActivities)
      // setCompletedJourneys(tempCompletedJourneys)
    }
  }, [myData])

  useEffect(() => {
    if (meData && meData.me && meData.me.bonusScore) {
      const data = separateAndSummarizeRiddleData(meData.me.bonusScore)
      setBonusScoreList(data)
    }
  }, [meData, meDataLoading])

  return (
    <Layout path={location.pathname}>
      <div className="row">
        <div className="flex-center">
          <img src={TETorch} alt="te-torch" className="torch-image" />
          <h1 className="big-title uppercase center no-margin--bottom">
            <Trans i18nKey={"Progress.title"} />
          </h1>
        </div>
        <div className="col s12 l8 offset-l2 center text">
          {hasPoints && (
            <p>
              {" "}
              <Trans i18nKey="Progress.keep_it_up" />
            </p>
          )}
          <div
            className="margin--top-40"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <PointProgress setHasPoints={setHasPoints} isDark size="large" />
          </div>
        </div>
      </div>
      <CompletedActivities
        completedActivities={completedActivities}
        loading={loading}
        bonusScoreList={bonusScoreList}
      />
      {/* <CompletedJourneys
        completedJourneys={completedJourneys}
        loading={loading}
      /> */}
    </Layout>
  )
}

export default withTranslation()(Progress)
